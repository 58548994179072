import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import {STATS_ENABLED, CONFIG_HISTORY} from 'config';

import { globalState } from '../state';
import Tooltip from './tooltip';
import GuildIcon from './guild_icon';

function partnerOrVerified(features){
  if (!features) return;

  let type;
  if (features.indexOf('VERIFIED') > -1) {
    type = 'verified';
  } else if (features.indexOf('PARTNERED') > -1) {
    type = 'partner';
  } else if (features.indexOf('PATREON') > -1) {
    type = 'patreon';
  } else if (features.indexOf('TEST') > -1) {
    type = 'test'
  } else if (features.indexOf('MVERIFIED') > -1) {
    type = 'mverified'
  } else if (features.indexOf('DISABLED') > -1) {
    type = 'disabled'
  } else if (features.indexOf('BOOSTER') > -1) {
    type = 'booster'
  }

  switch (type) {
    case 'verified':
      return <Tooltip position='right' text='Verified' trigger={<img src='https://hep.gg/verifiedbadge.png' height={24} />} />;
    case 'partner':
      return <Tooltip position='right' text='Discord Partner' trigger={<img src='https://hep.gg/partnerbadge.svg'height={24} />} />
    case 'patreon':
      return <Tooltip position='right' text='Patreon Supporter' trigger={<img src='https://hep.gg/omGZRK2RW.png'height={24} />} />
    case 'mverified':
      return <Tooltip position='right' text='Verified' trigger={<img src='https://hep.gg/verifiedbadge.png' height={24} />} />;
    case 'test':
      return <Tooltip position='right' text='Test Guild' trigger={<img src='https://hep.gg/00nwb.png'height={24} />} />
    case 'booster':
      return <Tooltip position='right' text='Team Hydra Booster' trigger={<img src='https://hep.gg/8sc44.png'height={24} />} />
    case 'disabled':
      return <Tooltip position='right' text='Guild Suspended' trigger={<img src='https://hep.gg/fhq1w.png'height={24} />} />
    default:
      break;
  }
  return null;
}

class GuildTableRowActions extends Component {
  render() {
    let parts = [];

    parts.push(
      <Link key='1' to={`/guilds/${this.props.guild.id}`} style={{paddingLeft: '4px'}}>
        <Tooltip position={'top'} interactive={false} text='Information' trigger={
          <button type='button' className='btn btn-success btn-circle'>
            <i className='fa fa-info'></i>
          </button>
        } />
      </Link>
    );

    parts.push(
      <Link key='2' to={`/guilds/${this.props.guild.id}/config`} style={{paddingLeft: '4px'}}>
        <Tooltip position={'top'} interactive={false} text='Configuration' trigger={
          <button type='button' className='btn btn-info btn-circle'>
            <i className='fa fa-cog'></i>
          </button>
        } />
      </Link>
    );

    if (CONFIG_HISTORY) {
      parts.push(
        <Link key='3' to={`/guilds/${this.props.guild.id}/config/history`} style={{paddingLeft: '4px'}}>
          <Tooltip position={'top'} interactive={false} text='Configuration History' trigger={
            <button type='button' className='btn btn-info btn-circle'>
              <i className='fa fa-history'></i>
            </button>
          } />
        </Link>
      );
    }

    parts.push(
      <Link key='4' to={`/guilds/${this.props.guild.id}/infractions`} style={{paddingLeft: '4px'}}>
        <Tooltip position={'top'} interactive={false} text='Infractions' trigger={
          <button type='button' className='btn btn-warning btn-circle'>
            <i className='fa fa-gavel'></i>
          </button>
        } />
      </Link>
    );

    if (STATS_ENABLED) {
        parts.push(
          <Link key='5' to={`/guilds/${this.props.guild.id}/stats`} style={{paddingLeft: '4px'}}>
            <Tooltip position={'top'} interactive={false} text='Statistics' trigger={
              <button type='button' className='btn btn-primary btn-circle'>
                <i className='fas fa-chart-bar'></i>
              </button>
            } />
          </Link>
        );
    }

    if (globalState.user && globalState.user.admin) {
      parts.push(
        <a key='6' href='#' style={{paddingLeft: '4px'}} onClick={this.onDelete.bind(this)}>
          <Tooltip position={'top'} interactive={false} text='Remove Server' trigger={
            <button type='button' className='btn btn-danger btn-circle'>
              <i className='fas fa-trash-alt'></i>
            </button>
          } />
        </a>
      );
    }

    return (
      <div>
        {parts}
      </div>
    );
  }

  onDelete() {
    this.props.guild.delete().then(() => {
      window.location.reload();
    });
  }
}

class GuildTableRow extends Component {
  render() {
    return (
      <tr>
        <td className='col-xs-6'>
          <GuildIcon guildID={this.props.guild.id} guildIcon={this.props.guild.icon} scale={26} className='guild-circle' />
            <Tooltip position='right' text={this.props.guild.id} trigger={this.props.guild.name} />
        </td>
        <td className='col-xs-5'><GuildTableRowActions guild={this.props.guild} /></td>
        <td className='col-xs-1'>{partnerOrVerified(this.props.guild.features)}</td>
      </tr>
    );
  }
}

class GuildsTable extends Component {
  render() {
    if (!this.props.guilds) {
      return <h3>Loading your guilds... owo</h3>;
    }

    let guilds = sortBy(Object.values(this.props.guilds), (i) => i.name);

    var rows = [];
    guilds.forEach((guild) => {
      if (guild.enabled) {
        rows.push(<GuildTableRow guild={guild} key={guild.id} />);
      }
    });

    return (
      <div className='table-responsive'>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th className='col-xs-6'>Name</th>
              <th className='col-xs-5'>Actions</th>
              <th className='col-xs-1'>Special</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }
}

export default GuildsTable;
